const prod = {
    url: {
      KEYCLOAK_BASE_URL: 'https://siat.meridianaitalia.eu:8443',
      API_BASE_URL: 'https://siat.meridianaitalia.eu/backend',
      FRONTEND_BASE_URL: 'https://siat.meridianaitalia.eu'
  },
    realm: "basic-users",
    clientId: "frontend_client",
    onLoad: 'check-sso',
    KeycloakResponseType: 'code',
    pkceMethod: 'S256'
}

  const dev = {
    url: {
	    KEYCLOAK_BASE_URL: 'http://localhost:8080',
      API_BASE_URL: 'http://localhost:8081',
      FRONTEND_BASE_URL:'http://localhost:3000'
    },
    realm: "basic-users",
    clientId: "frontend_client",
    onLoad: 'check-sso',
    KeycloakResponseType: 'code',
    pkceMethod: 'S256'
  }
  
  export const config = process.env.NODE_ENV === 'development' ? dev : prod
