import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";

function ModalDescNote({ show, handleClose, item, onUpdate, isTimeslot }) {

  const { token, updateToken } = useAuth();
  const [description, setDescription] = useState(item.description || "");
  const [note, setNote] = useState(item.note || "");
  const { t } = useTranslation();
  const maxCharacters = 2000;

  useEffect(() => {
    if (item) {
      setDescription(item.description || "");
      setNote(item.note || "");
    }
  }, [item]);

  const handleSaveDescription = async () => {
    const updatedItem = {
      ...item,
      description,
      note
    };

    try {
      await updateToken(); 
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('descriptionSaved'));
      }
    } catch (error) {
      toast.error(t('descriptionError'));
      console.error("Error while saving description:", error);
    }
  };

  const handleSaveNote = async () => {
    const updatedItem = {
      ...item,
      note,
      description
    };

    try {
      await updateToken();
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('notesSaved'));
      }
    } catch (error) {
      toast.error(t('notesError'));
      console.error("Error while saving notes:", error);
    }
  };

  const handleResetDescription = async () => {
    setDescription("");
    const updatedItem = {
      ...item,
      description: "",
      note
    };

    try {
      await updateToken(); 
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('descriptionSaved'));
      }
    } catch (error) {
      toast.error(t('descriptionError'));
      console.error("Error while saving description:", error);
    }
  };

  const handleResetNote = async () => {
    setNote("");
    const updatedItem = {
      ...item,
      note: "",
      description
    };

    try {
      await updateToken(); 
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('notesSaved'));
      }
    } catch (error) {
      toast.error(t('notesError'));
      console.error("Error while saving notes:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{t('descriptionAndNotes')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formDescription">
            <Form.Label className="green-color">{t('description')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={maxCharacters}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-100"
              style={{ fontSize: "1.1rem" }}
              placeholder={isTimeslot ? t('timeslotDescriptionPlaceholder') : t('leaveDescriptionPlaceholder')}
            />
            <div className="d-flex justify-content-between mt-2">
              <small>
                {description.length}/{maxCharacters}
              </small>
              <div>
                <Button
                  className="green-background text-white me-2 fw-semibold"
                  onClick={handleSaveDescription}
                >
                  {t('save')}
                </Button>
                <Button className="green-text-button fw-semibold" onClick={handleResetDescription}>
                  {t('reset')}
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="formNote" className="mt-4">
            <Form.Label className="green-color">{t('notes')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={maxCharacters}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="w-100"
              style={{ fontSize: "1.1rem" }}
              placeholder={isTimeslot ? t('timeslotNotesPlaceholder') : t('leaveNotesPlaceholder')}
            />
            <div className="d-flex justify-content-between mt-2">
              <small>
                {note.length}/{maxCharacters}
              </small>
              <div>
                <Button
                  className="green-background text-white me-2 fw-semibold"
                  onClick={handleSaveNote}
                >
                  {t('save')}
                </Button>
                <Button className="green-text-button fw-semibold" onClick={handleResetNote}>
                  {t('reset')}
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDescNote;
