import React, { useEffect } from "react";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainHeader from "./screens/components/MainHeader";
import Header from "./screens/components/Header";
import Timesheet from "./screens/Timesheet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";

function App() {
  const { isAuthenticated, handleLogin, isLoading } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      const timeoutId = setTimeout(() => {
        handleLogin();
      }, 500); // Ritardo di 1/2 secondo

      return () => clearTimeout(timeoutId);
    }
  }, [isAuthenticated, handleLogin]);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <ToastContainer autoClose={3000} />
      <div className="col-12">
        <MainHeader />
      </div>
      <div className="col-12">
        <Header />
      </div>
      <div className="container-fluid mt-2 px-5">
        <div className="row">
          <div className="col-6 text-start">
            <h4 className="fw-semibold">Timesheet</h4>
          </div>
        </div>
        <Timesheet />
      </div>
    </div>
  );
}

export default App;
