import React, { createContext, useContext, useState, useEffect } from "react";
import Keycloak from "keycloak-js";
import { config } from "./Constants";

const AuthContext = createContext();

const initOptions = {
  url: `${config.url.KEYCLOAK_BASE_URL}`,
  realm: `${config.realm}`,
  clientId: `${config.clientId}`,
  onLoad: `${config.onLoad}`, // check-sso | login-required
  KeycloakResponseType: `${config.KeycloakResponseType}`,
};

const kc = new Keycloak(initOptions);

let isKeycloakInitialized = false;

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogin = async () => {
    try {
      await kc.login();
      setIsAuthenticated(true);
      setToken(kc.token);
      setIsLoading(false);
    } catch (error) {
      console.error("Login failed", error);
      setIsLoading(false);
    } 
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await kc.logout();
      setIsAuthenticated(false);
      setToken(null);
    } catch (error) {
      console.error("Logout failed", error);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    if (!isKeycloakInitialized) {
      isKeycloakInitialized = true; 

      kc.init({
        onLoad: initOptions.onLoad,
        KeycloakResponseType: `${config.KeycloakResponseType}`,
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
        checkLoginIframe: false,
        pkceMethod: `${config.pkceMethod}`,
      }).then((authenticated) => {
        setIsAuthenticated(authenticated);
        setToken(kc.token);
        setIsLoading(false);
        kc.onTokenExpired = () => {
          updateToken();
        };
      }).catch((error) => {
        console.error("Failed to initialize Keycloak", error);
        setIsLoading(false);
      });
    }
  }, []); 

  const updateToken = async () => {
    const minValidity = 60; // 1 minuto prima della scadenza
    if (kc.tokenParsed && kc.tokenParsed.exp) {
      const tokenExpiresIn = kc.tokenParsed.exp * 1000 - new Date().getTime();
      if (tokenExpiresIn > minValidity * 1000) {
        return;
      }
    }
    try {
      const refreshed = await kc.updateToken(minValidity);
      if (refreshed) {
        setToken(kc.token);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Error updating token", error);
    }
  };

  return (
    <AuthContext.Provider value={{ token, isAuthenticated, isLoading, handleLogin, handleLogout, updateToken, kc }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
