import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img
          src={`${process.env.PUBLIC_URL}/${props.data.icon}.svg`}
          alt="Icon"
          style={{ width: "1rem" }}
          className="mr-2"
        />
        <span>{props.data.label}</span> 
      </div>
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <div className="d-flex align-items-center">
      <img
        src={`${process.env.PUBLIC_URL}/${props.data.icon}.svg`}
        alt="Icon"
        style={{ width: "1rem" }}
        className="mr-2"
      />
      <span>{props.data.label}</span> 
    </div>
  </components.SingleValue>
);

function ModalPlaceTimeslot({ show, handleClose, timeslot, onUpdate }) {

  const { token, updateToken } = useAuth();
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(
    timeslot.place ? {
      value: timeslot.place.value ? timeslot.place.value : timeslot.place.id,
      label: timeslot.place.label ? timeslot.place.label : timeslot.place.name,
      icon: timeslot.place.icon,
    } : null
  );
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue('--main-color').trim();

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        await updateToken(); 
        if (token) {
          const response = await timeslotsApi.getPlaces(timeslot.project.id, token);
          const formattedPlaces = response.data.map((place) => ({
            value: place.id,
            label: place.name,
            icon: place.icon
          }));
          setPlaces(formattedPlaces);
        }
      } catch (error) {
        console.error("Error while loading working places:", error);
      }
    };

    if (timeslot) {
      fetchPlaces();
    }
  }, [timeslot, token, updateToken]);

  const handleSavePlace = async () => {
    const updatedTimeslot = {
      ...timeslot,
      placePk: selectedPlace ? selectedPlace.value : null,  
    };
    try {
      await updateToken();
      if (token) {
        await timeslotsApi.updateTimeslot(timeslot.id, updatedTimeslot, token);
        onUpdate({ ...updatedTimeslot, place: selectedPlace });
        toast.success(t('placeSaved'));
      }
    } catch (error) {
      toast.error(t('placeError'));
      console.error("Error while saving working place:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{t('workingPlace')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPlace">
            <Form.Label className="green-color">{t('place')}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              value={selectedPlace}
              onChange={setSelectedPlace}
              options={places}  
              components={{ Option, SingleValue }}
              placeholder={t('placePlaceholder')}
              noOptionsMessage={() => t('placeNoOptions')} 
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: '#dee2e6',
                },
              })}
            />
            <div className="d-flex justify-content-end mt-2">
              <Button
                className="green-background text-white fw-semibold"
                onClick={handleSavePlace}
              >
                {t('save')}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPlaceTimeslot;
