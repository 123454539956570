import axios from 'axios'
import { config } from '../Constants'

export const timeslotsApi = {
  getTimeslots,
  getWeekTimeslots,
  getLeaves,
  getWeekLeaves,
  getLeaveTypes,
  getPlaces,
  getApiDocs,
  createTimeslot,
  updateTimeslot,
  deleteTimeslot,
  createLeave,
  updateLeave,
  deleteLeave,
  copyAllActivities,
  approveTimesheet,
  getProjectsByWeek,
  getPartnersByWeek,
  getCustomersByWeek,
  getProjectFiguresByWeek,
  getTasksByWeek,
  getFieldsByWeek,
  getSubtasksByWeek,
  getRecentActivities,
  createProject,
  getAllTemplates,
  getAllResources,
  getAllCategories,
  createCustomer,
  createPartner,
  createTask,
  createField,
  createSubtask,
  createOutput,
  createPlace,
  createTemplate,
  createResource,
  createProjectPreference, 
  getAllUnits,
  createUnit
}

function getWeekTimeslots(startDate,token) {
  return instance.get(`/api/timeslot/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate': startDate }
  })
}

function getWeekLeaves(startDate,token) {
  return instance.get(`/api/leave/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate': startDate }
  })
}

function getTimeslots(token) {
  return instance.get(`/api/timeslot`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}
function getLeaves(token) {
  return instance.get(`/api/leave`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getApiDocs(token) {
  return instance.get(`/v3/api-docs`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}
function getPlaces(projectPk,token) {
  return instance.get(`/api/timeslot/places`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'prjpk': projectPk }
  })
}

function getLeaveTypes(token) {
  return instance.get(`/api/leave/types`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createTimeslot(timeslot, token) {
  return instance.post('/api/timeslot', timeslot, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createLeave(leave, token) {
  return instance.post('/api/leave', leave, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function deleteTimeslot(tsId, token) {
  return instance.delete(`/api/timeslot/${tsId}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function deleteLeave(lvId, token) {
  return instance.delete(`/api/leave/${lvId}`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function updateTimeslot(tsId, timeslot, token) {
  return instance.put(`/api/timeslot/${tsId}`, timeslot, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function updateLeave(lvId, leave, token) {
  return instance.put(`/api/leave/${lvId}`, leave, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function copyAllActivities(startDate,token) {
  return instance.post(`/api/timeslot/copyWeek`, {}, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate}
  })
}

function approveTimesheet(startDate,token) {
  return instance.post(`/api/timeslot/approveWeek`, {}, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate}
  })
}

function getProjectsByWeek(startDate,token) {
  return instance.get(`/api/project/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate}
  })
}

function getPartnersByWeek(startDate, projectPk, token) {
  return instance.get(`/api/partner/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk}
  })
}

function getCustomersByWeek(startDate, projectPk, token) {
  return instance.get(`/api/customer/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk}
  })
}

function getProjectFiguresByWeek(startDate, projectPk, token) {
  return instance.get(`/api/projectfigure/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk}
  })
}

function getTasksByWeek(startDate, projectPk, token) {
  return instance.get(`/api/task/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk}
  })
}

function getFieldsByWeek(startDate, projectPk, token) {
  return instance.get(`/api/field/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk}
  })
}

function getSubtasksByWeek(startDate, projectPk, taskPk, fieldPk, token) {
  return instance.get(`/api/subtask/week`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'project':projectPk, 'task':taskPk, 'field':fieldPk}
  })
}

function getRecentActivities(startDate, days, token) {
  return instance.get(`/api/timeslot/recentActivities`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'startDate':startDate, 'daysRecent':days}
  })
}

function createProject(project, token) {
  return instance.post('/api/project', project, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getAllTemplates(token) {
  return instance.get(`/api/templates`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getAllResources(token, allUsers) {
  return instance.get(`/api/resource`, {
    headers: { 'Authorization': bearerAuth(token) },
    params: {'all':allUsers}
  })
}

function getAllCategories(token) {
  return instance.get(`/api/category`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createCustomer(customer, token) {
  return instance.post(`/api/customer`, customer, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createPartner(partner, token) {
  return instance.post(`/api/partner`, partner, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createTask(task, token) {
  return instance.post(`/api/task`, task, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createField(field, token) {
  return instance.post(`/api/field`, field, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createSubtask(subtask, token) {
  return instance.post(`/api/subtask`, subtask, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createOutput(output, token) {
  return instance.post(`/api/output`, output, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createPlace(place, token) {
  return instance.post(`/api/place`, place, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createTemplate(template, token) {
  return instance.post(`/api/templates`, template, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createResource(resource, token) {
  return instance.post(`/api/resource`, resource, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createProjectPreference(projectPreference, token) {
  return instance.post(`/api/projectpreference`, projectPreference, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getAllUnits(token) {
  return instance.get(`/api/unit`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createUnit(unit, token) {
  return instance.post(`/api/unit`, unit, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

/*function saveUserExtrasMe(token, userExtra) {
  return instance.post(`/api/userextras/me`, userExtra, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}*/

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response
}, function (error) {
  if (error.response.status === 404) {
    return { status: error.response.status }
  }
  return Promise.reject(error.response)
})

// -- Helper functions

function bearerAuth(token) {
  return `Bearer ${token}`
}