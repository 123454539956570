import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Tabs, Tab, ListGroup } from "react-bootstrap";
import Select from "react-select";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";

function ModalInsertTimeslot({ show, handleClose, startDate, timeslots }) {
  const { token, updateToken } = useAuth();
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projectFigures, setProjectFigures] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [fields, setFields] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProjectFigure, setSelectedProjectFigure] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [recents, setRecents] = useState([]);

  useEffect(() => {
    if (show) {
      const fetchProjects = async () => {
        await updateToken();
        if (token) {
          timeslotsApi
            .getProjectsByWeek(startDate, token)
            .then((response) => {
              const projectsData = response.data || [];
              if (projectsData.length > 0) {
                const projects = projectsData.map((project) => ({
                  value: project.id,
                  label: `${project.internalCode} - ${project.name}`,
                }));
                setProjects(projects);
              } else {
                setProjects([]);
              }
            })
            .catch((error) => {
              console.error("Error while loading projects:", error);
              setProjects([]);
            });
        }
      };
      fetchProjects();
    }
  }, [token, updateToken, startDate, show]);

  useEffect(() => {
    const fetchPartners = async () => {
      if (token && selectedProject) {
        await updateToken();
        timeslotsApi
          .getPartnersByWeek(startDate, selectedProject.value, token)
          .then((response) => {
            const partnersData = response.data || [];
            if (partnersData.length > 0) {
              const partners = partnersData.map((partner) => ({
                value: partner.id,
                label: partner.resource.company
                  ? partner.resource.companyName
                  : `${partner.resource.name} ${partner.resource.surname}`,
              }));
              setPartners(partners);
              if (partners.length === 1) {
                setSelectedPartner(partners[0]);
              }
            } else {
              setPartners([]);
            }
          })
          .catch((error) => {
            console.error("Error while loading partners:", error);
            setPartners([]);
          });
      } else {
        setPartners([]);
      }
    };
    fetchPartners();
  }, [selectedProject, token, updateToken, startDate]);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (token && selectedProject) {
        timeslotsApi
          .getCustomersByWeek(startDate, selectedProject.value, token)
          .then((response) => {
            const customersData = response.data || [];
            const customers = customersData.map((customer) => ({
              value: customer.id,
              label: customer.resource.company
                ? customer.resource.companyName
                : `${customer.resource.name} ${customer.resource.surname}`,
            }));
            setCustomers(customers);
            if (customers.length === 1) {
              setSelectedCustomer(customers[0]);
            }
          })
          .catch((error) => {
            console.error("Error while loading customers:", error);
            setCustomers([]);
          });
      }
    };
    fetchCustomers();
  }, [selectedProject, startDate, token]);

  useEffect(() => {
    const fetchProjectFigures = async () => {
      await updateToken();
      if (token && selectedProject) {
        timeslotsApi
          .getProjectFiguresByWeek(startDate, selectedProject.value, token)
          .then((response) => {
            const projectFiguresData = response.data || [];
            const projectFigures = projectFiguresData.map((projectFigure) => ({
              value: projectFigure.id,
              label: projectFigure.figure.name,
            }));
            setProjectFigures(projectFigures);
            if (projectFigures.length === 1) {
              setSelectedProjectFigure(projectFigures[0]);
            }
          })
          .catch((error) => {
            console.error("Error while loading project figures:", error);
            setProjectFigures([]);
          });
      }
    };
    fetchProjectFigures();
  }, [token, updateToken, startDate, selectedProject]);

  useEffect(() => {
    const fetchTasks = async () => {
      await updateToken();
      if (
        token &&
        selectedProject &&
        selectedPartner &&
        selectedCustomer &&
        selectedProjectFigure
      ) {
        timeslotsApi
          .getTasksByWeek(startDate, selectedProject.value, token)
          .then((response) => {
            const tasksData = response.data || [];
            const tasks = tasksData.map((task) => ({
              value: task.id,
              label: `${task.internalCode} - ${task.name}`,
            }));
            setTasks(tasks);
          })
          .catch((error) => {
            console.error("Error while loading tasks:", error);
            setTasks([]);
          });
      }
    };
    fetchTasks();
  }, [
    token,
    updateToken,
    startDate,
    selectedProject,
    selectedPartner,
    selectedCustomer,
    selectedProjectFigure,
  ]);

  useEffect(() => {
    const fetchFields = async () => {
      await updateToken();
      if (
        token &&
        selectedProject &&
        selectedPartner &&
        selectedCustomer &&
        selectedProjectFigure
      ) {
        timeslotsApi
          .getFieldsByWeek(startDate, selectedProject.value, token)
          .then((response) => {
            const fieldsData = response.data || [];
            const fields = fieldsData.map((field) => ({
              value: field.id,
              label: `${field.internalCode} - ${field.name}`,
            }));
            setFields(fields);
          })
          .catch((error) => {
            console.error("Error while loading fields:", error);
            setFields([]);
          });
      }
    };
    fetchFields();
  }, [
    token,
    updateToken,
    startDate,
    selectedProject,
    selectedPartner,
    selectedCustomer,
    selectedProjectFigure,
  ]);

  useEffect(() => {
    const fetchSubtasks = async () => {
      await updateToken();
      if (token && selectedProject && selectedTask) {
        timeslotsApi
          .getSubtasksByWeek(
            startDate,
            selectedProject.value,
            selectedTask.value,
            selectedField ? selectedField.value : -1,
            token
          )
          .then((response) => {
            const subtasksData = response.data || [];
            const subtasks = subtasksData.map((subtask) => ({
              value: subtask.id,
              label: `${subtask.internalCode} - ${subtask.name}`,
            }));
            setSubtasks(subtasks);
          })
          .catch((error) => {
            console.error("Error while loading subtasks:", error);
            setSubtasks([]);
          });
      }
    };
    fetchSubtasks();
  }, [
    token,
    updateToken,
    startDate,
    selectedProject,
    selectedTask,
    selectedField,
  ]);

  useEffect(() => {
    const checkForDuplicateTimeslot = () => {
      const duplicateExists = timeslots.some(
        (timeslot) =>
          timeslot.projectPk === selectedProject?.value &&
          timeslot.partnerPk === selectedPartner?.value &&
          timeslot.customerPk === selectedCustomer?.value &&
          timeslot.projectFigurePk === selectedProjectFigure?.value &&
          timeslot.taskPk === selectedTask?.value &&
          (!selectedField || timeslot.fieldPk === selectedField.value)
      );
      setIsDuplicate(duplicateExists);
    };
    checkForDuplicateTimeslot();
  }, [
    selectedProject,
    selectedPartner,
    selectedCustomer,
    selectedProjectFigure,
    selectedTask,
    selectedField,
    timeslots,
  ]);

  useEffect(() => {
    if (show) {
      const fetchRecents = async () => {
        await updateToken();
        if (token) {
          timeslotsApi
            .getRecentActivities(startDate, 7, token)
            .then((response) => {
              const recentsData = response.data;
              setRecents(recentsData);
            })
            .catch((error) => {
              console.error("Error while loading recents:", error);
              setRecents([]);
            });
        }
      };
      fetchRecents();
    }
  }, [token, updateToken, startDate, show]);

  const handleCreateNewTimeslots = async () => {
    if (
      !selectedProject ||
      !selectedPartner ||
      !selectedCustomer ||
      !selectedProjectFigure ||
      !selectedTask
    )
      return;
    await updateToken();
    const datesOfWeek = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");
    });
    try {
      const requests = datesOfWeek.map((date) => {
        return timeslotsApi.createTimeslot(
          {
            date,
            projectPk: selectedProject.value,
            partnerPk: selectedPartner.value,
            customerPk: selectedCustomer.value,
            projectFigurePk: selectedProjectFigure.value,
            taskPk: selectedTask.value,
            fieldPk: selectedField ? selectedField.value : null,
            subtaskPk: selectedSubtask ? selectedSubtask.value : null,
            time: "00:00:00",
          },
          token
        );
      });
      await Promise.all(requests);
      toast.success(t("timeslotsSaved"));
      handleClose();
    } catch (error) {
      console.error("Error creating timeslots:", error);
      toast.error(t("insertTimeslotError"));
    }
  };

  const handleCreateRecentsTimeslots = async (
    project,
    partner,
    customer,
    projectFigure,
    task,
    field,
    subtask
  ) => {
    await updateToken();
    const datesOfWeek = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");
    });
    try {
      const requests = datesOfWeek.map((date) => {
        return timeslotsApi.createTimeslot(
          {
            date,
            projectPk: project,
            partnerPk: partner,
            customerPk: customer,
            projectFigurePk: projectFigure,
            taskPk: task,
            fieldPk: field ? field : null,
            subtaskPk: subtask ? subtask : null,
            time: "00:00:00",
          },
          token
        );
      });
      await Promise.all(requests);
      toast.success(t("timeslotsSaved"));
      handleClose();
    } catch (error) {
      console.error("Error creating timeslots:", error);
      toast.error(t("insertTimeslotError"));
    }
  };

  const handleCreateAllRecentsTimeslots = async () => {
    await updateToken();
    const datesOfWeek = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");
    });
    try {
      const allRequests = recents.flatMap((recent) =>
        datesOfWeek.map((date) =>
          timeslotsApi.createTimeslot(
            {
              date,
              projectPk: recent.project.id,
              partnerPk: recent.partner.id,
              customerPk: recent.customer.resource.id,
              projectFigurePk: recent.projectFigure.id,
              taskPk: recent.task.id,
              fieldPk: recent.field ? recent.field.id : null,
              subtaskPk: recent.subtask ? recent.subtask.id : null,
              time: "00:00:00",
            },
            token
          )
        )
      );
      await Promise.all(allRequests);
      toast.success(t("allRecentsTimeslotsEntered"));
      handleClose();
    } catch (error) {
      console.error("Error creating timeslots:", error);
      toast.error(t("insertTimeslotsError"));
    }
  };

  useEffect(() => {
    if (!show) {
      setSelectedProject(null);
      setSelectedPartner(null);
      setPartners([]);
      setSelectedCustomer(null);
      setCustomers([]);
      setSelectedProjectFigure(null);
      setProjectFigures([]);
      setSelectedTask(null);
      setTasks([]);
      setSelectedField(null);
      setFields([]);
      setSelectedSubtask(null);
      setSubtasks([]);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title className="green-color">
          {t("insertTimeslotInWeek")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="recentsTab"
          id="insertTimeslotTabs"
          className="mb-3"
        >
          <Tab eventKey="recentsTab" title={t("recentsTimeslot")}>
            <Button
              className="green-text-button fw-semibold mt-2 mb-3"
              onClick={handleCreateAllRecentsTimeslots}
              disabled={!recents.length > 0}
            >
              <img
                src={`${process.env.PUBLIC_URL}/copy.svg`}
                alt="Insert all recent tasks"
                style={{ width: "2.5rem", cursor: "pointer" }}
                className="m-1"
              />
              {t("insertAllRecentsTimeslot")}
            </Button>
            <ListGroup>
              {recents && recents.length > 0 ? (
                recents.map((recent) => (
                  <ListGroup.Item
                    key={recent.id}
                    action
                    className="recent-activity-item"
                    onClick={() =>
                      handleCreateRecentsTimeslots(
                        recent.project.id,
                        recent.partner.id,
                        recent.customer.id,
                        recent.projectFigure.id,
                        recent.task.id,
                        recent.field ? recent.field.id : null,
                        recent.subtask ? recent.subtask.id : null
                      )
                    }
                  >
                    <div className="green-text">
                      {recent.subtask
                        ? `${recent.subtask.internalCode} - ${recent.subtask.name}`
                        : `${recent.task.internalCode} - ${recent.task.name}`}
                    </div>
                    <div>
                      {`${recent.project.internalCode} | ${
                        recent.customer.resource.company
                          ? recent.customer.resource.companyName
                          : `${recent.customer.resource.name} ${recent.customer.resource.surname}`
                      }${
                        recent.field
                          ? ` | ${recent.field.internalCode} - ${recent.field.name}`
                          : ""
                      }`}
                    </div>
                  </ListGroup.Item>
                ))
              ) : (
                <div className="text-danger">{t("noRecentsTimeslot")}</div>
              )}
            </ListGroup>
          </Tab>
          <Tab eventKey="newTab" title={t("newTimeslot")}>
            <Form>
              <Form.Group controlId="formSelectProject">
                <Form.Label className="green-color">{t("project")}</Form.Label>
                <Select
                  classNamePrefix="custom-select"
                  value={selectedProject}
                  onChange={(value) => {
                    setSelectedProject(value);
                    setSelectedPartner(null);
                    setSelectedCustomer(null);
                    setSelectedProjectFigure(null);
                    setSelectedTask(null);
                    setSelectedField(null);
                    setSelectedSubtask(null);
                  }}
                  options={projects}
                  placeholder={t("projectPlaceholder")}
                  noOptionsMessage={() => t("projectsNoOptions")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: mainColor,
                      primary: mainColor,
                      neutral20: '#dee2e6',
                    },
                  })}
                />
              </Form.Group>
              {selectedProject && (
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="formSelectPartner">
                      <Form.Label className="green-color mt-2">
                        {t("partner")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        value={selectedPartner}
                        onChange={(value) => {
                          setSelectedPartner(value);
                          setSelectedTask(null);
                          setSelectedField(null);
                          setSelectedSubtask(null);
                        }}
                        options={partners}
                        placeholder={t("partnerPlaceholder")}
                        noOptionsMessage={() => t("partnersNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: '#dee2e6',
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="formSelectCustomer">
                      <Form.Label className="green-color mt-2">
                        {t("customer")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        value={selectedCustomer}
                        onChange={(value) => {
                          setSelectedCustomer(value);
                          setSelectedTask(null);
                          setSelectedField(null);
                          setSelectedSubtask(null);
                        }}
                        options={customers}
                        placeholder={t("customerPlaceholder")}
                        noOptionsMessage={() => t("customersNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: '#dee2e6',
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4">
                    <Form.Group controlId="formSelectProjectFigure">
                      <Form.Label className="green-color mt-2">
                        {t("projectFigure")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        value={selectedProjectFigure}
                        onChange={(value) => {
                          setSelectedProjectFigure(value);
                          setSelectedTask(null);
                          setSelectedField(null);
                          setSelectedSubtask(null);
                        }}
                        options={projectFigures}
                        placeholder={t("projectFigurePlaceholder")}
                        noOptionsMessage={() => t("projectFiguresNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: '#dee2e6',
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
              )}
              {selectedProject &&
                selectedPartner &&
                selectedCustomer &&
                selectedProjectFigure && (
                  <div className="row">
                    <div className={fields.length === 0 ? "col-12" : "col-6"}>
                      <Form.Group controlId="formSelectTask">
                        <Form.Label className="green-color mt-2">
                          {t("task")}
                        </Form.Label>
                        <Select
                          classNamePrefix="custom-select"
                          value={selectedTask}
                          onChange={(value) => {
                            setSelectedTask(value);
                            setSelectedField(null);
                            setSelectedSubtask(null);
                          }}
                          options={tasks}
                          placeholder={t("taskPlaceholder")}
                          noOptionsMessage={() => t("tasksNoOptions")}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: mainColor,
                              primary: mainColor,
                              neutral20: '#dee2e6',
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                    {fields.length > 0 && (
                      <div className="col-6">
                        <Form.Group controlId="formSelectField">
                          <Form.Label className="green-color mt-2">
                            {t("field")}
                          </Form.Label>
                          <Select
                            classNamePrefix="custom-select"
                            value={selectedField}
                            onChange={(value) => {
                              setSelectedField(value);
                              setSelectedSubtask(null);
                            }}
                            options={fields}
                            placeholder={t("fieldPlaceholder")}
                            noOptionsMessage={() => t("fieldsNoOptions")}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: mainColor,
                                primary: mainColor,
                                neutral20: '#dee2e6',
                              },
                            })}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                )}
              {selectedProject && selectedTask && (
                <div className="row">
                  {subtasks.length > 0 && (
                    <div className="col-12">
                      <Form.Group controlId="formSelectSubtask">
                        <Form.Label className="green-color mt-2">
                          {t("subtask")}
                        </Form.Label>
                        <Select
                          classNamePrefix="custom-select"
                          value={selectedSubtask}
                          onChange={setSelectedSubtask}
                          options={subtasks}
                          placeholder={t("subtaskPlaceholder")}
                          noOptionsMessage={() => t("subtasksNoOptions")}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: mainColor,
                              primary: mainColor,
                              neutral20: '#dee2e6',
                            },
                          })}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
              )}
              <div className="d-flex justify-content-end align-items-center mt-2">
                {isDuplicate && (
                  <span className="alert alert-danger m-2">
                    {t("duplicateTimeslotWarning")}
                  </span>
                )}
                <Button
                  className="green-background text-white fw-semibold"
                  onClick={handleCreateNewTimeslots}
                  disabled={
                    !selectedProject ||
                    !selectedPartner ||
                    !selectedCustomer ||
                    !selectedProjectFigure ||
                    !selectedTask ||
                    isDuplicate
                  }
                >
                  {t("insertTimeslot")}
                </Button>
              </div>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default ModalInsertTimeslot;
