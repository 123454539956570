import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";
import ModalCreateProject from "./ModalCreateProject";
import ModalCreateResource from "./ModalCreateResource";
import '../../i18n'; 

const Header = () => {

  const { t } = useTranslation();
  const { isAuthenticated, kc, handleLogin, handleLogout } = useAuth();
  const userEmail = kc.tokenParsed?.email;
  const userRoles = kc.tokenParsed?.realm_access?.roles || [];  
  const isAdmin = userRoles.includes("admin-role"); 
  const { i18n } = useTranslation();
  const [showModalCreateProject, setShowModalCreateProject] = useState(false);
  const [showModalCreateResource, setShowModalCreateResource] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);  
  };

  const openModalCreateProject = () => setShowModalCreateProject(true);
  const closeModalCreateProject = () => setShowModalCreateProject(false);
  const openModalCreateResource = () => setShowModalCreateResource(true);
  const closeModalCreateResource = () => setShowModalCreateResource(false);

  return (
    <>
      <Navbar
        id="navbar"
        expand="lg"
        fixed="top"
        variant="dark"
        className="header px-4 py-3"
      >
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto text-center">
            {isAuthenticated && isAdmin && (
              <>
                <Nav.Link className="btn navbar-text text-white" onClick={openModalCreateProject}>
                  {t("projectCreation")}
                </Nav.Link>
                <Nav.Link className="btn navbar-text text-white" onClick={openModalCreateResource}>
                {t("resourceCreation")}
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav className="ms-auto text-center">
            {isAuthenticated ? (
              <>
                {userEmail && (
                  <span className="navbar-text text-white mx-2">
                    <FontAwesomeIcon icon={faUser} className="mx-2" />
                    {userEmail}
                  </span>
                )}
                <Nav.Link className="btn navbar-text text-white" onClick={handleLogout}>
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link className="btn navbar-text text-white" onClick={handleLogin}>
                Login
              </Nav.Link>
            )}
            <Nav.Link
              className="btn navbar-text text-white"
              onClick={() => changeLanguage('en')}
            >
              English
            </Nav.Link>
            <Nav.Link
              className="btn navbar-text text-white"
              onClick={() => changeLanguage('it')}
            >
              Italiano
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ModalCreateProject show={showModalCreateProject} handleClose={closeModalCreateProject} />
      <ModalCreateResource show={showModalCreateResource} handleClose={closeModalCreateResource} />
    </>
  );
};

export default Header;
